import React, {  useRef,useState,useEffect ,Fragment } from "react";
import { Modal,Table, Pagination, Badge,Dropdown,Spinner } from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";

import { useDispatch,useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from '../../services/AxiosInstance';
import swal from "sweetalert";

import { format } from "date-fns";


//const Orders = () => {
const Notifications = ({ props, history }) => {

   const dispatch = useDispatch();
   const [notifications, setNotifications] = useState([]);
   const [loading, setLoading] = useState(false);
   const [errors, setErrors] = useState({title:"",message:""});

   const [title, setTitle] = useState("");
   const [type, setType] = useState("all");
   const [message, setMessage] = useState("");
   const [addNotificationModal, setAddNotificationModal] = useState(false);

   const sort = 500;
   let pagination = Array(Math.ceil(notifications.length / sort))
   .fill()
   .map((_, i) => i + 1);

   const [activePage, setActivePage] = useState(0);
   const [tableData, setTableData] = useState([]);
  
  
   useEffect(() => {
		loadNotifications();
	}, [dispatch]);


   useEffect(() => {

      if(notifications){
         setLoading(false);
         setTableData( notifications.slice(
            activePage * sort,
            (activePage + 1) * sort
         ));
      }
     
	}, [notifications]);


   const loadNotifications = async () => {

      const { data } = await axiosInstance.post(
         "admin/notifications"
       );
 
       if(data.status){
         setNotifications(data.notifications);
       }
   }

   
     //const [demo, setdemo] = useState();
     const onClick = (i) => {
      console.log("onClick - "+i);
      setActivePage(i);
      setTableData(notifications.slice(
         i * sort,
         (i + 1) * sort
      ));
   };


   const onSendNotification = async (event) => {
      event.preventDefault();

      try {
			
         const { data } = await axiosInstance.post(
            "admin/send-notification",
            { message:message,title:title }
         );
 
 
     if(data.status === false){
        //toast.error(data.message);
      swal('Send Notification', data.message,"error");
 
     }else{
        //toast.success(data.message);
      setAddNotificationModal(false);
      swal('Send Notification', data.message, "success");
      setMessage("");
      setTitle("");
      loadNotifications();
 
     }
      }catch (error) {
         swal('Send Notification', error,"error");
      }

   }

   return (

      <>
      
      <Modal
        
        className="modal fade"
        show={addNotificationModal}
        onHide={setAddNotificationModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSendNotification}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Send Notification</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setAddNotificationModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                <div className="add-contact-content">


                        <div className="form-group">
                          <label className="text-black font-w500">
                            Send To
                          </label>
                          <select
                            className="form-control"
                            value={type}
                            onChange={(e) =>
                              setType(e.target.value)
                            }
                            defaultValue="No"
                          >
                            <option value="all">All Users</option>
                            
                          </select>
                        </div>


                        <div className="form-group mb-3">
                            <label className="text-black font-w500">Title</label>
                            <div className="contact-name">
                              <input
                                type="text"
                                value={title}
                                className="form-control"
                                autocomplete="off"
                                name="title"
                                required="required"
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Title"
                              />
                              {errors.title && (
                                <div className="text-danger fs-12">
                                  {errors.title}
                                </div>
                              )}

                              <span className="validation-text"></span>
                            </div>
                          </div>

                          <div className="form-group mb-3">
                            <label className="text-black font-w500">Message</label>
                            <div className="contact-name">

                              <textarea 
                                rows="5"
                                type="text"
                                value={message}
                                className="form-control"
                                autocomplete="off"
                                name="message"
                                required="required"
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Message"
                              />

                              {errors.message && (
                                <div className="text-danger fs-12">
                                  {errors.message}
                                </div>
                              )}

                              <span className="validation-text"></span>
                            </div>
                          </div>


                     </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setAddNotificationModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

     

      <Fragment>
         {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

      <div className="col-12">
         <div className="card">
            <div className="card-header">
               <h4 className="card-title mr-auto">Notifications</h4>

               <Link className="btn btn-light font-w600 mr-2" onClick={()=> setAddNotificationModal(true)}>Send Notification</Link>
				    
            </div>
            <div className="card-body">

               {loading || notifications.length == 0 ? <div className="text-center mt-4"><Spinner animation="border" variant="primary" /></div>:(
       
       <Table responsive className="w-100">
       <div id="example_wrapper" className="dataTables_wrapper">
          <table id="example" className="display w-100 dataTable">
             <thead>
                <tr role="row">
                   <th>Id</th>
                   <th>Title</th>
                   <th>Message</th>
                   <th>Date</th>
                </tr>
             </thead>
             <tbody>
                {tableData.map((d, i) => (
                   <tr key={i}>

                      {/* <td ><Link to={"/user/"+d.id}>#{d.id}</Link></td> */}
                      <td >#{d.id}</td> 
                      
                     <td>{d.title}</td>
                     <td>{d.message}</td>
                     <td>{ format(new Date(d.createdAt), "dd/MM/yyyy H:mma")}</td>

                   </tr>
                ))}
             </tbody>
             <tfoot>
                <tr role="row">
                   <th>Id</th>
                   <th>Title</th>
                   <th>Message</th>
                   <th>Date</th>
                
                  

                </tr>
             </tfoot>
          </table>
          <div className="d-flex justify-content-between align-items-center mt-3">
             <div className="dataTables_info">
                Showing {activePage * sort + 1} to&nbsp;
                {notifications.length <
                (activePage + 1) * sort
                   ? notifications.length
                   : (activePage + 1) * sort}
                &nbsp;of {notifications.length} entries
             </div>
             <div className="dataTables_paginate paging_simple_numbers">
                <Pagination
                   className="pagination-primary pagination-circle"
                   size="lg"
                >
                   <li
                      className="page-item page-indicator "
                      onClick={() =>
                         activePage > 1 &&
                         onClick(activePage - 1)
                      }
                   >
                      <Link className="page-link" to="#">
                         <i className="la la-angle-left" />
                      </Link>
                   </li>
                   {pagination.map((number, i) => (
                         <Pagination.Item
                         key={"page-"+i}
                         className={
                            activePage === i ? "active" : ""
                         }
                         onClick={() => onClick(i)}
                      >
                         {number}
                      </Pagination.Item>
                   ))}
                   <li
                      className="page-item page-indicator"
                      onClick={() =>
                         activePage + 1 <
                            pagination.length &&
                         onClick(activePage + 1)
                      }
                   >
                      <Link className="page-link" to="#">
                         <i className="la la-angle-right" />
                      </Link>
                   </li>
                </Pagination>
             </div>
          </div>
       </div>
    </Table>
       
              )}
       


             
         </div>
      </div>
      </div>

      </Fragment>

      </>
   );
};

export default Notifications;
