import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Modal,
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
} from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";
import Select from "react-select";

import moment from "moment";

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const UserTable = ({ users, onEditMember, props, history }) => {
  const dispatch = useDispatch();

  const sort = users.length > 100 ? Math.round(users.length / 15) : 100;

  let pagination = Array(Math.ceil(users.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const [activePage, setActivePage] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [usersList, setUsersList] = useState(users);

  const [activeUser, setActiveUser] = useState(null);
  const [agents, setAgents] = useState([]);

  const [activeAgent, setActiveAgent] = useState(null);

  const [agentModal, setAgentModal] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState("");

  const [createInstanceModal, setCreateInstanceModal] = useState(false);

  const [total, setTotal] = useState("1");
  const [valid, setValid] = useState("365");
  const [expireDate, setExpireDate] = useState(new Date());
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setTableData(usersList.slice(activePage * sort, (activePage + 1) * sort));
  }, [dispatch]);

  //const [demo, setdemo] = useState();
  const onClick = (i) => {
    console.log("onClick - " + i);
    setActivePage(i);
    setTableData(usersList.slice(i * sort, (i + 1) * sort));
  };

  // Update Profile Submit data
  const onCreateInstance = async (event) => {
    event.preventDefault();

    let error = false;
    var errorObj = {};

    if (total === "") {
      error = true;
      errorObj.total = "Instance is Required";
    }

    if (valid === "") {
      error = true;
      errorObj.valid = "Validity is Required";
    }

    if (expireDate === "") {
      error = true;
      errorObj.email = "ExpireDate is Required";
    }

    if (!error) {
      try {
        const { data } = await axiosInstance.post("admin/create-instance", {
          instance: total,
          user_id: activeUser.id,
          expire_date: expireDate,
        });

        if (data.status === false) {
          //toast.error(data.message);
          swal("Assign Instance", data.message, "error");
        } else {
          //toast.success(data.message);
          swal("Assign Instance", data.message, "success");
          setTotal("1");
          setValid("365");
          setExpireDate(Date.now());

          setTimeout(() => {
            setCreateInstanceModal(false);
          }, 500);
        }
      } catch (error) {}
    }
  };

  // On Edit Profile
  const onSendCounter = (member) => {
    setTotal("1");
    setValid("365");

    var currentDate = Date.now();
    var ed = moment(currentDate);
    ed.add(365, "days").format("YYYY-MM-DD hh:mm");
    setExpireDate(ed);

    setActiveUser(member);
    setCreateInstanceModal(true);
  };

  const removeAgentFromUser = async (user) => {
    const { data } = await axiosInstance.post("admin/remove-reseller", {
      user_id: user.id,
    });

    if (data.status) {
      swal("Remove Reseller", data.message, "success");

      var dummy = [...usersList];

      const index = dummy.findIndex((x) => x.id === user.id);

      dummy[index].resellerId = null;
      console.log("user index - " + index);

      setUsersList(dummy);
      onClick(activePage);
    } else {
      swal("Remove Reseller", data.message, "error");
    }
  };

  const handleAssignAgent = async (event) => {
    event.preventDefault();

    const { data } = await axiosInstance.post("admin/assign-reseller", {
      user_id: activeUser.id,
      reseller_id: selectedAgentId,
    });

    if (data.status) {
      swal("Assign Reseller", data.message, "success");
      setAgentModal(false);

      var dummy = [...usersList];

      const index = dummy.findIndex((x) => x.id === activeUser.id);
      const agent = agents.find((agent) => agent._id === selectedAgentId);

      dummy[index].resellerId = agent;
      console.log("user index - " + index);

      setUsersList(dummy);
      onClick(activePage);
    } else {
      swal("Assign Reseller", data.message, "error");
    }
  };

  const assignAgent = async (user) => {
    loadAgents();
    setActiveUser(user);
    setSelectedAgentId(user.resellerId ? user.resellerId.id : "");
    setAgentModal(true);
  };

  const loadAgents = async () => {
    const { data } = await axiosInstance.post("admin/resellers");

    if (data.status) {
      setAgents(data.resellers);
      if (data.resellers.length > 0) {
        setSelectedAgentId(data.resellers._id);
      }
    }
  };

  const changeUserStatus = async (userId, status) => {
    const { data } = await axiosInstance.post("admin/user-status", {
      user_id: userId,
      status: status,
    });

    if (data.status) {
      swal("User", data.message, "success");

      var dummy = [...usersList];

      const index = dummy.findIndex((x) => x.id === userId);

      dummy[index].status = status;
      console.log("order index - " + index);

      setUsersList(dummy);
      onClick(activePage);
    } else {
      swal("User", data.message, "error");
    }
  };

  const makeAgent = async (userId) => {
    const { data } = await axiosInstance.post("admin/make-reseller", {
      user_id: userId,
    });

    if (data.status) {
      swal("Make Reseller", data.message, "success");

      var dummy = [...usersList];
      const index = dummy.findIndex((x) => x.id === userId);

      //dummy[index].status = status;
      console.log("order index - " + index);
      dummy.splice(index, 1);

      setUsersList(dummy);
      onClick(activePage);
    } else {
      swal("Make Reseller", data.message, "error");
    }
  };

  return (
    <>
      <Modal
        className="modal fade"
        show={createInstanceModal}
        onHide={setCreateInstanceModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onCreateInstance}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Create Instance</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setCreateInstanceModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <p>
                      Create Instance in {activeUser ? activeUser.name : ""}(
                      {activeUser ? activeUser.phone : ""}) Account
                    </p>
                    <br />

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Number Of Instance
                      </label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={total}
                          className="form-control"
                          autocomplete="off"
                          name="counter"
                          onkeypress="return event.charCode>=48 && event.charCode<=57"
                          required="required"
                          min={1}
                          onChange={(e) => setTotal(e.target.value)}
                          placeholder="Total Instance"
                        />

                        {errors.total && (
                          <div className="text-danger fs-12">
                            {errors.total}
                          </div>
                        )}
                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Valid</label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={valid}
                          className="form-control"
                          autocomplete="off"
                          onChange={(e) => {
                            setValid(e.target.value);

                            var currentDate = Date.now();
                            var ed = moment(currentDate);
                            ed.add(e.target.value, "days").format(
                              "YYYY-MM-DD hh:mm"
                            );

                            setExpireDate(ed);
                          }}
                          placeholder="Validity In Days"
                        />
                        {errors.valid && (
                          <div className="text-danger fs-12">
                            {errors.valid}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Expire At</label>
                      <div className="contact-name">
                        <MuiPickersUtilsProvider
                          className="form-control"
                          utils={DateFnsUtils}
                        >
                          <DateTimePicker
                            inputVariant="outlined"
                            label="Expire Date"
                            value={expireDate}
                            format="dd/MM/yyyy hh:mm"
                            placeholder="dd/MM/yyyy hh:mm"
                            onChange={(date) => {
                              setExpireDate(date);
                              var startDate = moment(expireDate).startOf("day");
                              var endDate = moment(date).startOf("day");

                              var result = endDate.diff(startDate, "days");
                              setValid(result);
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        {errors.expireDate && (
                          <div className="text-danger fs-12">
                            {errors.expireDate}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setCreateInstanceModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal className="modal fade" show={agentModal} onHide={setAgentModal}>
        <div className="" role="document">
          <div className="">
            <form onSubmit={handleAssignAgent}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Assign Reseller</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setAgentModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <p>
                      Assign agent to {activeUser ? activeUser.name : ""} (
                      {activeUser ? activeUser.phone : ""}) to
                    </p>
                    <br />

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Reseller</label>
                      <div className="contact-name">
                        <select
                          className="form-control"
                          value={selectedAgentId}
                          onChange={(e) => setSelectedAgentId(e.target.value)}
                          // options={agentOption()}
                          // style={{
                          //   lineHeight: "40px",
                          //   color: "#7e7e7e",
                          //   paddingLeft: " 15px",
                          // }}
                        >
                          {agents.map((a, i) => {
                            return (
                              <option key={i} value={a._id}>
                                {a.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setAgentModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Assign
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Table responsive className="w-100">
        <div id="example_wrapper" className="dataTables_wrapper">
          <table id="example" className="display w-100 dataTable">
            <thead>
              <tr role="row">
                <th key="0">Sr No.</th>
                <th key="1">Name</th>
                <th key="2">Phone</th>
                <th key="">Reseller</th>
                <th key="4">Joined</th>
                <th key="5">Status</th>
                <th key="6">Action</th>
              </tr>
            </thead>
            <tbody style={{ height: "300px" }}>
              {tableData.map((d, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>

                  <Link to={"/user/" + d.id}>
                        {d.name}
                      </Link>

                    {/* {d.name}
                    <br />
                    <small>{d.email}</small> */}
                  </td>

                  <td>{d.phone}</td>

                  <td>
                    {d.resellerId ? (
                      <Link to={"/reseller/" + d.resellerId.id}>
                        {d.resellerId.name}
                      </Link>
                    ) : (
                      <></>
                    )}
                  </td>

                  <td>{format(new Date(d.createdAt), "dd/MM/yyyy H:mma")}</td>
                  <td>
                    {d.status === "active" ? (
                      <Badge variant="success light">
                        Activated
                        <span className="ml-1 fa fa-check"></span>
                      </Badge>
                    ) : d.status === "deactive" ? (
                      <Badge variant="danger light">
                        Deactivated
                        <span className="ml-1 fa fa-ban"></span>
                      </Badge>
                    ) : (
                      <></>
                    )}
                  </td>

                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="table-dropdown icon-false"
                      >
                        <svg
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <circle
                              fill="#000000"
                              cx="5"
                              cy="12"
                              r="2"
                            ></circle>
                            <circle
                              fill="#000000"
                              cx="12"
                              cy="12"
                              r="2"
                            ></circle>
                            <circle
                              fill="#000000"
                              cx="19"
                              cy="12"
                              r="2"
                            ></circle>
                          </g>
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as="button"
                          onClick={(e) =>
                            changeUserStatus(
                              d.id,
                              d.status === "active" ? "deactive" : "active"
                            )
                          }
                        >
                          {d.status === "active"
                            ? "Deactive Now"
                            : "Active Now"}
                        </Dropdown.Item>

                        <Dropdown.Item
                          as="button"
                          onClick={(e) => onEditMember(d)}
                        >
                          Edit Profile
                        </Dropdown.Item>

                        <Dropdown.Item
                          as="button"
                          onClick={(e) => onSendCounter(d)}
                        >
                          Create Instant
                        </Dropdown.Item>

                        <Dropdown.Item
                          as="button"
                          onClick={(e) => assignAgent(d)}
                        >
                          {d.resellerId
                            ? "Change User's Reseller"
                            : "Assign Reseller"}
                        </Dropdown.Item>

                        {d.resellerId ? (
                          <Dropdown.Item
                            as="button"
                            onClick={(e) => removeAgentFromUser(d)}
                          >
                            Remove Reseller
                          </Dropdown.Item>
                        ) : (
                          <></>
                        )}

                        <Dropdown.Item
                          as="button"
                          onClick={(e) => makeAgent(d.id)}
                        >
                          Make Reseller
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr role="row">
                <th key="0">Sr No.</th>
                <th key="1">Name</th>
                <th key="2">Phone</th>
                <th key="">Reseller</th>
                <th key="4">Joined</th>
                <th key="5">Status</th>
                <th key="6">Action</th>
              </tr>
            </tfoot>
          </table>

          <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              Showing {activePage * sort + 1} to &nbsp;
              {users.length < (activePage + 1) * sort
                ? users.length
                : (activePage + 1) * sort}
              &nbsp;of {users.length} entries
            </div>
            <div className="dataTables_paginate paging_simple_numbers">
              <Pagination
                className="pagination-primary pagination-circle"
                size="lg"
              >
                <li
                  className="page-item page-indicator "
                  onClick={() => activePage > 1 && onClick(activePage - 1)}
                >
                  <Link className="page-link" to="#">
                    <i className="la la-angle-left" />
                  </Link>
                </li>
                {pagination.map((number, i) => (
                  <Pagination.Item
                    key={"page-" + i}
                    className={activePage === i ? "active" : ""}
                    onClick={() => onClick(i)}
                  >
                    {number}
                  </Pagination.Item>
                ))}
                <li
                  className="page-item page-indicator"
                  onClick={() =>
                    activePage + 1 < pagination.length &&
                    onClick(activePage + 1)
                  }
                >
                  <Link className="page-link" to="#">
                    <i className="la la-angle-right" />
                  </Link>
                </li>
              </Pagination>
            </div>
          </div>
        </div>
      </Table>
    </>
  );
};

export default UserTable;
