import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

import LogoutPage from "./Logout";
/// Image
import profileImg from "../../../images/profile/17.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown, Button } from "react-bootstrap";

import axiosInstance from "../../../services/AxiosInstance";
import { useDispatch, useSelector } from "react-redux";

import { getProfile, profileAction } from "../../../store/actions/AuthActions";

const Header = ({ onNote, toggle, onProfile, onNotification, onClick }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);

  const profile = useSelector((state) => state.auth.profile);

  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;

  useEffect(() => {
    loadProfile();
    //dispatch(getProfile());
  }, [dispatch]);

  async function loadProfile() {
    try {
      const { data } = await axiosInstance.get("auth/profile");

      console.log("profile data - " + JSON.stringify(data));

      if (data.status === false) {
      } else {
        //setUser(data.user);
        dispatch(profileAction(data.user));
      }
    } catch (error) {}
  }

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                id="nav_title"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ")}
              </div>
            </div>
            <ul className="navbar-nav header-right">
              {/* <li className="nav-item">
							<div className="input-group search-area d-lg-inline-flex d-none mr-5">
							   <input
								  type="text"
								  className="form-control"
								  placeholder="Search here"
							   />
							   <div className="input-group-append">
								  <span className="input-group-text">
									 <svg
										width={20}
										height={20}
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									 >
										<path
										   d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
										   fill="#A4A4A4"
										/>
									 </svg>
								  </span>
							   </div>
							</div>
						</li> */}

              <Dropdown as="li" className="nav-item header-profile ">
                <Dropdown.Toggle
                  as="a"
                  to="#"
                  variant=""
                  className="nav-link i-false c-pointer"
                >
                  <img
                    src="https://img.icons8.com/3d-fluency/94/administrator-male--v1.png"
                    width="20"
                    alt=""
                  />
                  {/* <div className="header-info">
									<span>David<i className="fa fa-caret-down ml-3" aria-hidden="true"></i></span>
									</div> */}
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" className="mt-2">
                  <Link to="/my-profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ml-2">Profile </span>
                  </Link>

                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
