import React, {  useRef,useState,useEffect ,Fragment } from "react";
import { Table, Pagination, Badge,Dropdown,Spinner } from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";

import { useDispatch,useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from '../../services/AxiosInstance';
import swal from "sweetalert";

import { format } from "date-fns";


//const Orders = () => {
const Feedbacks = ({ props, history }) => {

   const dispatch = useDispatch();
   const [feedbacks, setFeedbacks] = useState([]);
   const [loading, setLoading] = useState(false);

   const sort = 500;
   let pagination = Array(Math.ceil(feedbacks.length / sort))
   .fill()
   .map((_, i) => i + 1);

const [activePage, setActivePage] = useState(0);
const [tableData, setTableData] = useState([]);
  
  
   useEffect(() => {
		loadFeedbacks();
	}, [dispatch]);


   useEffect(() => {

      if(feedbacks){
         setLoading(false);
         setTableData( feedbacks.slice(
            activePage * sort,
            (activePage + 1) * sort
         ));
      }
     
	}, [feedbacks]);


   const loadFeedbacks = async () => {

      const { data } = await axiosInstance.get(
         "admin/feedbacks"
       );
 
       if(data.status){
         setFeedbacks(data.feedbacks);
       }
   }

   
     //const [demo, setdemo] = useState();
     const onClick = (i) => {
      console.log("onClick - "+i);
      setActivePage(i);
      setTableData(feedbacks.slice(
         i * sort,
         (i + 1) * sort
      ));
   };

   return (

      <Fragment>
         {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

      <div className="col-12">
         <div className="card">
            <div className="card-header">
               <h4 className="card-title">Feedbacks</h4>
            </div>
            <div className="card-body">

               {loading ? <div className="text-center mt-4"><Spinner animation="border" variant="primary" /></div>: (feedbacks.length == 0 ? (<p>No Feedback Found</p>):(
       
       <Table responsive className="w-100">
       <div id="example_wrapper" className="dataTables_wrapper">
          <table id="example" className="display w-100 dataTable">
             <thead>
                <tr role="row">
                   <th key="0">Feedback Id</th>
                   <th key="1">Name</th>
                   <th key="2">Phone</th>
                 
                   <th key="4">Message</th>
                   <th key="5">Date</th>
                </tr>
             </thead>
             <tbody>
                {tableData.map((d, i) => (
                   <tr key={i}>

                      {/* <td ><Link to={"/user/"+d.id}>#{d.id}</Link></td> */}
                      <td >#{d.id}</td> 
                      
                        <td>
                     {d.userId.name}
                     <br />
                     <small>{d.userId.email}</small>
                  </td>
                     
                      <td>{d.userId.phone}</td>
                    
                      <td>{d.message}</td>
                     
                      <td>{ format(new Date(d.createdAt), "dd/MM/yyyy H:mma")}</td>

                   </tr>
                ))}
             </tbody>
             <tfoot>
                <tr role="row">

                <th key="0">Feedback Id</th>
                   <th key="1">Name</th>
                   <th key="2">Phone</th>
                  
                   <th key="4">Message</th>
                   <th key="5">Date</th>
                
                  

                </tr>
             </tfoot>
          </table>
          <div className="d-flex justify-content-between align-items-center mt-3">
             <div className="dataTables_info">
                Showing {activePage * sort + 1} to&nbsp;
                {feedbacks.length <
                (activePage + 1) * sort
                   ? feedbacks.length
                   : (activePage + 1) * sort}
                &nbsp;of {feedbacks.length} entries
             </div>
             <div className="dataTables_paginate paging_simple_numbers">
                <Pagination
                   className="pagination-primary pagination-circle"
                   size="lg"
                >
                   <li
                      className="page-item page-indicator "
                      onClick={() =>
                         activePage > 1 &&
                         onClick(activePage - 1)
                      }
                   >
                      <Link className="page-link" to="#">
                         <i className="la la-angle-left" />
                      </Link>
                   </li>
                   {pagination.map((number, i) => (
                         <Pagination.Item
                         key={"page-"+i}
                         className={
                            activePage === i ? "active" : ""
                         }
                         onClick={() => onClick(i)}
                      >
                         {number}
                      </Pagination.Item>
                   ))}
                   <li
                      className="page-item page-indicator"
                      onClick={() =>
                         activePage + 1 <
                            pagination.length &&
                         onClick(activePage + 1)
                      }
                   >
                      <Link className="page-link" to="#">
                         <i className="la la-angle-right" />
                      </Link>
                   </li>
                </Pagination>
             </div>
          </div>
       </div>
    </Table>)
       
              )}
       


             
         </div>
      </div>
      </div>

      </Fragment>
   );
};

export default Feedbacks;
