import React, {  useRef,useState,useEffect ,Fragment } from "react";
import { Table, Pagination, Badge,Dropdown,Spinner } from "react-bootstrap";


import PageTitle from "../layouts/PageTitle";

import { useDispatch,useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from '../../services/AxiosInstance';
import swal from "sweetalert";

import { format } from "date-fns";

const OrderTable = ({orders, props, history }) => {


   const dispatch = useDispatch();
   const sort = 50;

   let pagination = Array(Math.ceil(orders.length / sort))
      .fill()
      .map((_, i) => i + 1);


   const [activePage, setActivePage] = useState(0);
   const [tableData, setTableData] = useState([]);
   const [ordersList, setOrdersList] = useState(orders);


   useEffect(() => {
		setTableData( ordersList.slice(
         activePage * sort,
         (activePage + 1) * sort
      ));
	}, [dispatch]);

  
   const changeOrderStatus = async (orderId,status) => {

      const { data } = await axiosInstance.post(
         "admin/order-status",
         {
            order_id:orderId,
            status:status
         }
      );

      if(data.status){
         swal('Order', data.message, "success");

         var dummy = [...ordersList];
         
         const orderIndex = dummy.findIndex(
            (x) => x.id === orderId
         );

         dummy[orderIndex].status = status;
         console.log("order index - "+orderIndex);

         setOrdersList(dummy);
         onClick(activePage);
      }else{
         swal('Order', data.message, "error");
      }

   }


   

   //const [demo, setdemo] = useState();
   const onClick = (i) => {
      console.log("onClick - "+i);
      setActivePage(i);
      setTableData(ordersList.slice(
         i * sort,
         (i + 1) * sort
      ));
   };


   return (

    <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                     <table id="example" className="display w-100 dataTable">
                        <thead>
                           <tr role="row">
                              <th>Order Id</th>
                              <th>Name</th>
                              <th>Phone</th>
                              <th>Date</th>
                              <th>Status</th>
                              <th>Payment</th>
                              <th>Amount</th>
                              <th>Action</th>
                              {/* {data.feeTable.columns.map((d, i) => (
                                 <th key={i}>{d}</th>
                              ))} */}
                           </tr>
                        </thead>
                        <tbody>
                           {tableData.map((d, i) => (
                              <tr key={d.id}>

                                 <td><Link to={"/order/"+d.id}>#{d.id}</Link></td>
                                 <td>{d.name}</td>
                                 <td>{d.userId.phone}</td>
                                 <td>{ format(new Date(d.createdAt), "dd/MM/yyyy H:mma")}</td>
                                 <td>

                                 {d.status === "Completed"? (
                                 
                                       <Badge variant="success text-white">
                                             Completed
                                             <span className="ml-1 fa fa-check"></span>
                                       </Badge>)
                                       
                                 :d.status === "On Hold" ? (
                                          <Badge variant="secondary">
                                             On Hold
                                             <span className="ml-1 fa fa-ban"></span>
                                          </Badge>
                                       ):d.status === "Pending" ? (
                                          <Badge variant="warning">
                                             Pending
                                          </Badge>
                                       ):d.status === "Processing" ? (
                                          <Badge variant="primary text-white">
                                             Processing
                                          </Badge>
                                       ):(<></>)
                                 }


                                 </td>
                                 <td>
                                 
                                 {d.paymentStatus === "paid" ? (
                                          <Badge variant="success light">
                                             Paid
                                          </Badge>
                                       ) : (
                                          <Badge variant="danger light">
                                             Unpaid
                                          </Badge>
                                       )
                                    }
                                 
                                 </td>
                                 <td><strong>{d.currency+" "+d.orderTotal}</strong></td>

                                 <td>

                                 <Dropdown>
                                             <Dropdown.Toggle
                                                variant=""
                                                className="table-dropdown icon-false"
                                             >
                                                <svg
                                                   width="24px"
                                                   height="24px"
                                                   viewBox="0 0 24 24"
                                                   version="1.1"
                                                >
                                                   <g
                                                      stroke="none"
                                                      strokeWidth="1"
                                                      fill="none"
                                                      fillRule="evenodd"
                                                   >
                                                      <rect
                                                         x="0"
                                                         y="0"
                                                         width="24"
                                                         height="24"
                                                      ></rect>
                                                      <circle
                                                         fill="#000000"
                                                         cx="5"
                                                         cy="12"
                                                         r="2"
                                                      ></circle>
                                                      <circle
                                                         fill="#000000"
                                                         cx="12"
                                                         cy="12"
                                                         r="2"
                                                      ></circle>
                                                      <circle
                                                         fill="#000000"
                                                         cx="19"
                                                         cy="12"
                                                         r="2"
                                                      ></circle>
                                                   </g>
                                                </svg>
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu>
                                                <Dropdown.Item as="button" onClick={(e)=>changeOrderStatus(d.id,"Completed")}>
                                                   Completed
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" onClick={(e)=>changeOrderStatus(d.id,"Processing")}>
                                                   Processing
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" onClick={(e)=>changeOrderStatus(d.id,"On Hold")}>
                                                   On Hold
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" onClick={(e)=>changeOrderStatus(d.id,"Pending")}>
                                                   Pending
                                                </Dropdown.Item>
                                               {/*  <div className="dropdown-divider"></div>
                                                <Dropdown.Item
                                                   href="#"
                                                   className="text-danger"
                                                >
                                                   Delete
                                                </Dropdown.Item> */}
                                             </Dropdown.Menu>
                                          </Dropdown>

                                 </td>

                                 
                              </tr>
                           ))}
                        </tbody>
                        <tfoot>
                           <tr role="row">

                              <th>Order Id</th>
                              <th>Name</th>
                              <th>Phone</th>
                             
                              <th>Date</th>
                              <th>Status</th>
                              <th>Payment</th>
                              <th>Amount</th>
                              <th>Action</th>
                            
                           </tr>
                        </tfoot>
                     </table>
                     <div className="d-flex justify-content-between align-items-center mt-3">
                        <div className="dataTables_info">
                           Showing {activePage * sort + 1} to 
                           {orders.length <
                           (activePage + 1) * sort
                              ? orders.length
                              : (activePage + 1) * sort}
                           &nbsp;of {orders.length} entries
                        </div>
                        <div className="dataTables_paginate paging_simple_numbers">
                           <Pagination
                              className="pagination-primary pagination-circle"
                              size="lg"
                           >
                              <li
                                 className="page-item page-indicator "
                                 onClick={() =>
                                    activePage > 1 &&
                                    onClick(activePage - 1)
                                 }
                              >
                                 <Link className="page-link" to="#">
                                    <i className="la la-angle-left" />
                                 </Link>
                              </li>
                              {pagination.map((number, i) => (
                                    <Pagination.Item
                                    key={i}
                                    className={
                                       activePage === i ? "active" : ""
                                    }
                                    onClick={() => onClick(i)}
                                 >
                                    {number}
                                 </Pagination.Item>
                              ))}
                              <li
                                 className="page-item page-indicator"
                                 onClick={() =>
                                    activePage + 1 <
                                       pagination.length &&
                                    onClick(activePage + 1)
                                 }
                              >
                                 <Link className="page-link" to="#">
                                    <i className="la la-angle-right" />
                                 </Link>
                              </li>
                           </Pagination>
                        </div>
                     </div>
                  </div>
               </Table>
           
   );
};

export default OrderTable;
