import React, { useRef, useState, useEffect, Fragment } from "react";
import {
  Modal,
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
} from "react-bootstrap";

import PageTitle from "../layouts/PageTitle";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";

import { format } from "date-fns";

//import { Document, Page } from 'react-pdf';
import { Document, Outline, Page } from "react-pdf/dist/esm/entry.webpack";

import samplePDF from "./test.pdf";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

//const Orders = () => {
const Cards = ({ props, history }) => {
  const dispatch = useDispatch();
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);

  const sort = 500;
  let pagination = Array(Math.ceil(cards.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const [activePage, setActivePage] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [singlePage, setSinglePage] = useState(true);
   const [isLandscape, setIsLandscape] = useState(false);

  const [name, setName] = useState("");
  const [type, setType] = useState("");

  const [frontT, setFrontT] = useState("");
  const [frontL, setFrontL] = useState("");
  const [frontW, setFrontW] = useState("");
  const [frontH, setFrontH] = useState("");

  const [backT, setBackT] = useState("");
  const [backL, setBackL] = useState("");
  const [backW, setBackW] = useState("");
  const [backH, setBackH] = useState("");

  const [addCardModal, setAddCardModal] = useState(false);
  const [editCardModal, setEditCardModal] = useState(false);

  let errorsObj = { name: "", type: "", front: "", back: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [activeCard, setActiveCard] = useState(null);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [pdfFile, setPdfFile] = useState(null);

  const [height, setHeight] = useState(595);
  const [width, setWidth] = useState(842);

//   function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//     //var elements = document.getElementsByClassName("react-pdf__Page__canvas");
//     //let w = document.getElementsByClassName("react-pdf__Page__canvas")[0].getAttribute("width");
//     //let h = document.getElementsByClassName("react-pdf__Page__canvas")[0].getAttribute("height");

//     //console.log("w - "+w+", h - "+h);

//     //setWidth(Number(w));
//     //setHeight(Number(h));
//   }

  const onDocumentLoadSuccess = async (pdfObject) => {
   // If you want to get the dimension for page 1 (1-indexed)
   const page = await pdfObject.getPage(1);

   //console.log("page - "+page);
   //console.log("page - "+JSON.stringify(page));

   // console.log("page width - "+page.originalWidth);
   // console.log("page height - "+page.originalHeight);

   // console.log("page height - "+page.originalHeight);

   setNumPages(pdfObject.numPages);
   // setWidth(page.originalWidth);
   // setHeight(page.originalHeight);

 };

 function onPageLoad(info) {
   const {
     height, width, originalHeight, originalWidth
   } = info;
   
   console.log(height, width, originalHeight, originalWidth);

   setWidth(width);
   setHeight(height);

 }

  function onItemClick({ pageNumber: itemPageNumber }) {
    setPageNumber(itemPageNumber);
  }

  useEffect(() => {
    loadCards();
  }, [dispatch]);

  useEffect(() => {
    if (cards) {
      setLoading(false);
      setTableData(cards.slice(activePage * sort, (activePage + 1) * sort));
    }
  }, [cards]);

  const loadCards = async () => {
    const { data } = await axiosInstance.post("admin/cards");

    if (data.status) {
      setCards(data.cards);
    }
  };

  //const [demo, setdemo] = useState();
  const onClick = (i) => {
    console.log("onClick - " + i);
    setActivePage(i);
    setTableData(cards.slice(i * sort, (i + 1) * sort));
  };

  const onEdit = async (card) => {
    setActiveCard(card);
    setSinglePage(card.isSinglePage);
    setIsLandscape(card.isLandscape);

    setName(card.name);
    setType(card.type);

    setFrontT(card.front.top);
    setFrontL(card.front.left);
    setFrontW(card.front.width);
    setFrontH(card.front.height);

    setBackT(card.back.top);
    setBackL(card.back.left);
    setBackW(card.back.width);
    setBackH(card.back.height);

    setPdfFile(null);
    setEditCardModal(true);
    
  };

  const onAdd = async (card) => {
    setActiveCard(null);
    setSinglePage(true);
    setIsLandscape(false);

    setName("");
    setType("");

    setFrontT("");
    setFrontL("");
    setFrontW("");
    setFrontH("");

    setBackT("");
    setBackL("");
    setBackW("");
    setBackH("");

    setPdfFile(null);
    setAddCardModal(true);
  };

  const onDelete = async (card) => {
    try {
      const { data } = await axiosInstance.post("admin/delete-card", {
        card_id: card.id,
      });

      if (data.status === false) {
        swal("Delete Card", data.message, "error");
      } else {
        swal("Delete Card", data.message, "success");

        setTimeout(() => {
          loadCards();
        }, 500);
      }
    } catch (error) {}
  };

  const onAddCard = async (event) => {
    event.preventDefault();

    let error = false;
    var errorObj = {};

    if (name === "") {
      error = true;
      errorObj.name = "Name is Required";
    }

    if (type === "") {
      error = true;
      errorObj.name = "Type is Required";
    }

    if (frontL === "" || frontT === "" || frontW === "" || frontH === "") {
      error = true;
      errorObj.name = "Front LTWH is Required";
    }

    if (backL === "" || backT === "" || backW === "" || backH === "") {
      error = true;
      errorObj.name = "Back LTWH is Required";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post("admin/add-card", {
          single_page: singlePage,
          is_landscape: isLandscape,
          name: name,
          type: type,
          front: {
            left: frontL,
            top: frontT,
            width: frontW,
            height: frontH,
          },
          back: {
            left: backL,
            top: backT,
            width: backW,
            height: backH,
          },
        });

        if (data.status === false) {
          swal("Add Card", data.message, "error");
        } else {
          setAddCardModal(false);
          swal("Add Card", data.message, "success");

          setTimeout(() => {
            loadCards();
          }, 500);
        }
      } catch (error) {}
    }
  };

  const onUpdateCard = async (event) => {
    event.preventDefault();

    let error = false;
    var errorObj = {};

    if (name === "") {
      error = true;
      errorObj.name = "Name is Required";
    }

    if (type === "") {
      error = true;
      errorObj.name = "Type is Required";
    }

    if (frontL === "" || frontT === "" || frontW === "" || frontH === "") {
      error = true;
      errorObj.name = "Front LTWH is Required";
    }

    if (backL === "" || backT === "" || backW === "" || backH === "") {
      error = true;
      errorObj.name = "Back LTWH is Required";
    }

    setErrors(errorObj);
    if (!error) {
      try {
        const { data } = await axiosInstance.post("admin/update-card", {
          card_id: activeCard.id,
          single_page: singlePage,
          is_landscape:isLandscape,
          name: name,
          type: type,
          front: {
            left: frontL,
            top: frontT,
            width: frontW,
            height: frontH,
          },
          back: {
            left: backL,
            top: backT,
            width: backW,
            height: backH,
          },
        });

        if (data.status === false) {
          swal("Update Card", data.message, "error");
        } else {
          setEditCardModal(false);
          swal("Update Card", data.message, "success");

          setTimeout(() => {
            loadCards();
          }, 500);
        }
      } catch (error) {}
    }
  };

  const upload = (e) => {
    e.preventDefault();
    setPdfFile(null);
    document.getElementById("selectFile").click();
  };

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const readUploadFile = (e) => {
    e.preventDefault();

    console.log("readUploadFile");

    if (e.target.files) {
      console.log("readUploadFile file found");
      const reader = new FileReader();
      const fileByteArray = [];
      reader.onload = (e) => {
        const data = e.target.result;
        setPdfFile(data);
        //   data.arrayBuffer().then(buff => {
        //    let x = new Uint8Array(buff); // x is your uInt8Array
        //    // perform all required operations with x here.
        //    console.log(x);
        //    setPdfFile(x);
        //    });
        //   e.target.value = null;
      };
      reader.readAsArrayBuffer(e.target.files[0]);
      /*  reader.onloadend = (evt) => {
         if (evt.target.readyState === FileReader.DONE) {
           const arrayBuffer = evt.target.result,
             array = new Uint8Array(arrayBuffer);
           for (const a of array) {
             fileByteArray.push(a);
           }
           setPdfFile(fileByteArray);
           console.log(fileByteArray)
         }
       } */
    } else {
      console.log("readUploadFile file not found");
    }
  };

  return (
    <>
      <input
        id="selectFile"
        accept=".pdf, application/pdf"
        hidden
        type="file"
        onChange={readUploadFile}
        onClick={handleClick}
      />
      <Modal
        size="xl"
        className="modal fade"
        show={addCardModal}
        onHide={setAddCardModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onAddCard}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Add Card</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setAddCardModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="row">
                      <div className="col-md-6">
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="text-black font-w500">
                            Single Page Card ?
                          </label>
                          <select
                            className="form-control"
                            value={singlePage ? "Yes" : "No"}
                            onChange={(e) =>
                              setSinglePage(e.target.value == "Yes")
                            }
                            defaultValue="Yes"
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>

                        <div className="form-group col-md-6">
                          <label className="text-black font-w500">
                            Is Landscape ?
                          </label>
                          <select
                            className="form-control"
                            value={isLandscape ? "Yes" : "No"}
                            onChange={(e) =>
                              setIsLandscape(e.target.value == "Yes")
                            }
                            defaultValue="No"
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>

                        </div>
                        <div className="form-row">
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">Name</label>
                            <div className="contact-name">
                              <input
                                type="text"
                                value={name}
                                className="form-control"
                                autocomplete="off"
                                name="name"
                                required="required"
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Name"
                              />
                              {errors.name && (
                                <div className="text-danger fs-12">
                                  {errors.name}
                                </div>
                              )}

                              <span className="validation-text"></span>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">Type</label>
                            <div className="contact-name">
                              <input
                                type="text"
                                value={type}
                                className="form-control"
                                autocomplete="off"
                                name="name"
                                required="required"
                                onChange={(e) => setType(e.target.value)}
                                placeholder="Type"
                              />
                              {errors.type && (
                                <div className="text-danger fs-12">
                                  {errors.type}
                                </div>
                              )}

                              <span className="validation-text"></span>
                            </div>
                          </div>
                        </div>

                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Front</label>
                          <div className="contact-name row">
                            <div className="col-md-3 text-center">
                              <label className="text-black">T</label>
                              <input
                                type="number"
                                value={frontT}
                                className="form-control"
                                autocomplete="off"
                                name="ft"
                                required="required"
                                onChange={(e) => setFrontT(e.target.value)}
                                placeholder="Top"
                              />
                            </div>
                            <div className="col-md-3 text-center">
                              <label className="text-black">L</label>
                              <input
                                type="number"
                                value={frontL}
                                className="form-control"
                                autocomplete="off"
                                name="fl"
                                required="required"
                                onChange={(e) => setFrontL(e.target.value)}
                                placeholder="Left"
                              />
                            </div>

                            <div className="col-md-3 text-center">
                              <label className="text-black">W</label>
                              <input
                                type="number"
                                value={frontW}
                                className="form-control"
                                autocomplete="off"
                                name="fl"
                                required="required"
                                onChange={(e) => setFrontW(e.target.value)}
                                placeholder="Width"
                              />
                            </div>

                            <div className="col-md-3 text-center">
                              <label className="text-black">H</label>
                              <input
                                type="number"
                                value={frontH}
                                className="form-control"
                                autocomplete="off"
                                name="fl"
                                required="required"
                                onChange={(e) => setFrontH(e.target.value)}
                                placeholder="Height"
                              />
                            </div>

                            {errors.front && (
                              <div className="text-danger fs-12">
                                {errors.front}
                              </div>
                            )}

                            <span className="validation-text"></span>
                          </div>
                        </div>

                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Back</label>
                          <div className="contact-name row">
                            <div className="col-md-3 text-center">
                              <label className="text-black">T</label>
                              <input
                                type="number"
                                value={backT}
                                className="form-control"
                                autocomplete="off"
                                name="ft"
                                required="required"
                                onChange={(e) => setBackT(e.target.value)}
                                placeholder="Top"
                              />
                            </div>
                            <div className="col-md-3 text-center">
                              <label className="text-black">L</label>
                              <input
                                type="number"
                                value={backL}
                                className="form-control"
                                autocomplete="off"
                                name="fl"
                                required="required"
                                onChange={(e) => setBackL(e.target.value)}
                                placeholder="Left"
                              />
                            </div>

                            <div className="col-md-3 text-center">
                              <label className="text-black">W</label>
                              <input
                                type="number"
                                value={backW}
                                className="form-control"
                                autocomplete="off"
                                name="fl"
                                required="required"
                                onChange={(e) => setBackW(e.target.value)}
                                placeholder="Width"
                              />
                            </div>

                            <div className="col-md-3 text-center">
                              <label className="text-black">H</label>
                              <input
                                type="number"
                                value={backH}
                                className="form-control"
                                autocomplete="off"
                                name="fl"
                                required="required"
                                onChange={(e) => setBackH(e.target.value)}
                                placeholder="Height"
                              />
                            </div>

                            {errors.front && (
                              <div className="text-danger fs-12">
                                {errors.front}
                              </div>
                            )}

                            <span className="validation-text"></span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="text-center">
                          <Link
                            className="btn btn-primary font-w600"
                            id="plus"
                            onClick={upload}
                          >
                            Upload PDF
                          </Link>

                          {pdfFile ? (
                            <>
                              <Document
                                file={pdfFile}
                                onLoadSuccess={onDocumentLoadSuccess}
                              >
                                <Outline onItemClick={onItemClick} />
                                <Page
                                  pageNumber={pageNumber || 1}
                                  onLoadSuccess={onPageLoad}
                                  width={400}
                                >
                                  <div
                                    className="text-center"
                                    style={{
                                      backgroundColor: "#4caf5050",
                                      position: "absolute",
                                      left: Number(frontL ?? 0) * width + "px",
                                      top: Number(frontT ?? 0) * height + "px",
                                      width: Number(frontW ?? 0) * width + "px",
                                      height: Number(frontH ?? 0) * height + "px",
                                    }}
                                  ></div>

                                  <div
                                    className="text-center"
                                    style={{
                                      backgroundColor: "#ff572250",
                                      position: "absolute",
                                      left: Number(backL ?? 0) * width + "px",
                                      top: Number(backT ?? 0) * height + "px",
                                      width: Number(backW ?? 0) * width + "px",
                                      height: Number(backH ?? 0) * height + "px",
                                    }}
                                  ></div>
                                </Page>
                              </Document>
                              <p>
                                Page {pageNumber} of {numPages}
                              </p>
                            </>
                          ) : (
                            <div className="text-center">
                              <p></p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setAddCardModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        size={"xl"}
        className="modal fade"
        show={editCardModal}
        onHide={setEditCardModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onUpdateCard}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Update Card</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setEditCardModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="row">
                      <div className="col-md-6">

                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="text-black font-w500">
                            Single Page Card ?
                          </label>
                          <select
                            className="form-control"
                            value={singlePage ? "Yes" : "No"}
                            onChange={(e) =>
                              setSinglePage(e.target.value == "Yes")
                            }
                            defaultValue="Yes"
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>

                        <div className="form-group col-md-6">
                          <label className="text-black font-w500">
                            Is Landscape ?
                          </label>
                          <select
                            className="form-control"
                            value={isLandscape ? "Yes" : "No"}
                            onChange={(e) =>
                              setIsLandscape(e.target.value == "Yes")
                            }
                            defaultValue="No"
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>

                        </div>

                        <div className="form-row">
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">Name</label>
                            <div className="contact-name">
                              <input
                                type="text"
                                value={name}
                                className="form-control"
                                autocomplete="off"
                                name="name"
                                required="required"
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Name"
                              />
                              {errors.name && (
                                <div className="text-danger fs-12">
                                  {errors.name}
                                </div>
                              )}

                              <span className="validation-text"></span>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">Type</label>
                            <div className="contact-name">
                              <input
                                type="text"
                                value={type}
                                className="form-control"
                                autocomplete="off"
                                name="name"
                                required="required"
                                onChange={(e) => setType(e.target.value)}
                                placeholder="Type"
                              />
                              {errors.type && (
                                <div className="text-danger fs-12">
                                  {errors.type}
                                </div>
                              )}

                              <span className="validation-text"></span>
                            </div>
                          </div>
                        </div>

                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Front</label>
                          <div className="contact-name row">
                            <div className="col-md-3 text-center">
                              <label className="text-black">T</label>
                              <input
                                type="number"
                                value={frontT}
                                className="form-control"
                                autocomplete="off"
                                name="ft"
                                required="required"
                                onChange={(e) => setFrontT(e.target.value)}
                                placeholder="Top"
                              />
                            </div>
                            <div className="col-md-3 text-center">
                              <label className="text-black">L</label>
                              <input
                                type="number"
                                value={frontL}
                                className="form-control"
                                autocomplete="off"
                                name="fl"
                                required="required"
                                onChange={(e) => setFrontL(e.target.value)}
                                placeholder="Left"
                              />
                            </div>

                            <div className="col-md-3 text-center">
                              <label className="text-black">W</label>
                              <input
                                type="number"
                                value={frontW}
                                className="form-control"
                                autocomplete="off"
                                name="fl"
                                required="required"
                                onChange={(e) => setFrontW(e.target.value)}
                                placeholder="Width"
                              />
                            </div>

                            <div className="col-md-3 text-center">
                              <label className="text-black">H</label>
                              <input
                                type="number"
                                value={frontH}
                                className="form-control"
                                autocomplete="off"
                                name="fl"
                                required="required"
                                onChange={(e) => setFrontH(e.target.value)}
                                placeholder="Height"
                              />
                            </div>

                            {errors.front && (
                              <div className="text-danger fs-12">
                                {errors.front}
                              </div>
                            )}

                            <span className="validation-text"></span>
                          </div>
                        </div>

                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Back</label>
                          <div className="contact-name row">
                            <div className="col-md-3 text-center">
                              <label className="text-black">T</label>
                              <input
                                type="number"
                                value={backT}
                                className="form-control"
                                autocomplete="off"
                                name="ft"
                                required="required"
                                onChange={(e) => setBackT(e.target.value)}
                                placeholder="Top"
                              />
                            </div>
                            <div className="col-md-3 text-center">
                              <label className="text-black">L</label>
                              <input
                                type="number"
                                value={backL}
                                className="form-control"
                                autocomplete="off"
                                name="fl"
                                required="required"
                                onChange={(e) => setBackL(e.target.value)}
                                placeholder="Left"
                              />
                            </div>

                            <div className="col-md-3 text-center">
                              <label className="text-black">W</label>
                              <input
                                type="number"
                                value={backW}
                                className="form-control"
                                autocomplete="off"
                                name="fl"
                                required="required"
                                onChange={(e) => setBackW(e.target.value)}
                                placeholder="Width"
                              />
                            </div>

                            <div className="col-md-3 text-center">
                              <label className="text-black">H</label>
                              <input
                                type="number"
                                value={backH}
                                className="form-control"
                                autocomplete="off"
                                name="fl"
                                required="required"
                                onChange={(e) => setBackH(e.target.value)}
                                placeholder="Height"
                              />
                            </div>

                            {errors.front && (
                              <div className="text-danger fs-12">
                                {errors.front}
                              </div>
                            )}

                            <span className="validation-text"></span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="text-center">
                          <Link
                            className="btn btn-primary font-w600"
                            id="plus"
                            onClick={upload}
                          >
                            Upload PDF
                          </Link>

                          {pdfFile ? (
                            <>
                              <TransformWrapper><TransformComponent><Document
                                file={pdfFile}
                                onLoadSuccess={onDocumentLoadSuccess}
                              >
                                <Outline onItemClick={onItemClick} />
                                <Page
                                  pageNumber={pageNumber || 1}
                                  onLoadSuccess={onPageLoad}
                                  width={500}
                                  //height={height}
                                >
                                  <div
                                    className="text-center"
                                    style={{
                                      backgroundColor: "#4caf5050",
                                      position: "absolute",
                                      left: Number(frontL ?? 0) *width + "px",
                                      top: Number(frontT ?? 0) * height + "px",
                                      width: Number(frontW ?? 0) * width + "px",
                                      height: Number(frontH ?? 0) * height + "px",
                                    }}
                                  ></div>

                                  <div
                                    className="text-center"
                                    style={{
                                      backgroundColor: "#ff572250",
                                      position: "absolute",
                                      left: Number(backL ?? 0) * width + "px",
                                      top: Number(backT ?? 0) * height + "px",
                                      width: Number(backW ?? 0) * width + "px",
                                      height: Number(backH ?? 0) * height + "px",
                                    }}
                                  ></div>
                                </Page>
                              </Document></TransformComponent>
                              </TransformWrapper>
                              <p>
                                Page {pageNumber} of {numPages}
                              </p>
                            </>
                          ) : (
                            <div className="text-center">
                              <p></p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setEditCardModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Fragment>
        {/* <PageTitle activeMenu="Datatable" motherMenu="Table" /> */}

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mr-auto">Cards</h4>

              <Link
                className="btn btn-light font-w600 mr-2"
                onClick={() => onAdd()}
              >
                Add Card
              </Link>
            </div>
            <div className="card-body">
              {loading || cards.length == 0 ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <table id="example" className="display w-100 dataTable">
                      <thead>
                        <tr role="row">
                          <th>Sr No.</th>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Updated At</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((d, i) => (
                          <tr key={i}>
                            {/* <td ><Link to={"/user/"+d.id}>#{d.id}</Link></td> */}
                            <td>{i + 1}</td>

                            <td>{d.name}</td>
                            <td>{d.type}</td>

                            <td>
                              {format(
                                new Date(d.updatedAt),
                                "dd/MM/yyyy H:mma"
                              )}
                            </td>

                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="table-dropdown icon-false"
                                >
                                  <svg
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onEdit(d)}
                                  >
                                    Edit
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onDelete(d)}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr role="row">
                          <th>Sr No.</th>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Updated At</th>
                          <th>Action</th>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="dataTables_info">
                        Showing {activePage * sort + 1} to&nbsp;
                        {cards.length < (activePage + 1) * sort
                          ? cards.length
                          : (activePage + 1) * sort}
                        &nbsp;of {cards.length} entries
                      </div>
                      <div className="dataTables_paginate paging_simple_numbers">
                        <Pagination
                          className="pagination-primary pagination-circle"
                          size="lg"
                        >
                          <li
                            className="page-item page-indicator "
                            onClick={() =>
                              activePage > 1 && onClick(activePage - 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-left" />
                            </Link>
                          </li>
                          {pagination.map((number, i) => (
                            <Pagination.Item
                              key={"page-" + i}
                              className={activePage === i ? "active" : ""}
                              onClick={() => onClick(i)}
                            >
                              {number}
                            </Pagination.Item>
                          ))}
                          <li
                            className="page-item page-indicator"
                            onClick={() =>
                              activePage + 1 < pagination.length &&
                              onClick(activePage + 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-right" />
                            </Link>
                          </li>
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </Table>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Cards;
