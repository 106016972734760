import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///
 import drump from "../../../images/card/drump.png";

class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
      // this.mm("dispose");
      // console.log(this.mm);
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
   /// Open menu
   componentDidMount() {
      // sidebar open/close
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);   
   }
   render() {
      /// Path
      let path = window.location.pathname;
      path = path.split("/");
      path = path[path.length - 1];

      /// Active menu
      let deshBoard = ["",],

         orders = ["orders"],

         campaign = [
            "voucher",
         ];

      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <MM className="metismenu" id="menu">
                
					
               <li className={`${path === "" ? "mm-active" : ""}`}>
                     <Link
                        to="/"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-home-2"></i>
                        <span className="nav-text">Dashboard</span>
                     </Link>
                  </li>

                 

                  <li className={`${path === "users" ? "mm-active" : ""}`}>
                     <Link
                        to="/users"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-user-9"></i>
                        <span className="nav-text">Users</span>
                     </Link>
                  </li>

                  <li className={`${path === "instances" ? "mm-active" : ""}`}>
                     <Link
                        to="/instances"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-smartphone-5"></i>
                        <span className="nav-text">Instances</span>
                     </Link>
                  </li>

                   


                  <li className={`${orders.includes(path) ? "mm-active" : ""}`}>
                     <Link
                        to="/orders"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-box"></i>
                        <span className="nav-text">Orders</span>
                     </Link>
                  </li>

                {  <li className={`${path === "resellers" ? "mm-active" : ""}`}>
                     <Link
                        to="/resellers"
                        className="ai-icon"
                        
                     >
                         <i className="flaticon-381-user-7"></i>
                        <span className="nav-text">Resellers</span>
                     </Link>
                  </li>
}

                  <li className={`${path === "feedbacks" ? "mm-active" : ""}`}>
                     <Link
                        to="/feedbacks"
                        className="ai-icon"
                        
                     >
                        <i className="flaticon-381-help-1"></i>
                        <span className="nav-text">Feedbacks</span>
                     </Link>
                  </li>

                 {/*  <li className={`${path === "cards" ? "mm-active" : ""}`}>
                     <Link
                        to="/cards"
                        className="ai-icon"
                        
                     >
                       <i className="flaticon-381-id-card-1"></i>
                        
                        <span className="nav-text">Cards</span>
                     </Link>
                  </li> */}
                  
               
                {/*   <li className={`${path === "notifications" ? "mm-active" : ""}`}>
                     <Link
                        to="/notifications"
                        className="ai-icon"
                     >
                         <i className="flaticon-381-ring-1"></i>
                        <span className="nav-text">Notifications</span>
                     </Link>
                  </li> */}


                  {/* <li
                           className={`${
                              campaign.includes(path) ? "mm-active" : ""
                           }`}
                        >
                           <Link
                              className="ai-icon has-arrow"
                              to="#"
                              
                           >
                               <i className="flaticon-381-promotion"></i>
                        
                        <span className="nav-text">Campaign</span>
                              
                           </Link>
                           <ul
                              
                              className={`${
                                 campaign.includes(path) ? "mm-show" : ""
                              }`}
                           >
                              <li>
                                 <Link
                                    className={`${
                                       path === "vouchers"
                                          ? "mm-active"
                                          : ""
                                    }`}
                                    to="/vouchers"
                                 >
                                    Vouchers
                                 </Link>

                                 <Link
                                    className={`${
                                       path === "coupons"
                                          ? "mm-active"
                                          : ""
                                    }`}
                                    to="/coupons"
                                 >
                                    Coupons
                                 </Link>

                              </li>
                            
                           </ul>
                        </li>
 */}
                        <li className={`${path === "setting" ? "mm-active" : ""}`}>
                     <Link
                        to="/setting"
                        className="ai-icon"
                        
                     >
                       <i className="flaticon-381-settings-6"></i>
                        
                        <span className="nav-text">Setting</span>
                     </Link>
                  </li>

					
               </MM>
              

               <div className="copyright">
                  <p>
                     <strong>{process.env.REACT_APP_NAME}</strong> ©All Rights Reserved
                  </p>
                  <p>By {process.env.REACT_APP_BY}</p>
               </div>
            </PerfectScrollbar>
         </div>
      );
   }
}

export default SideBar;
