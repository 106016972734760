import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import axiosInstance from "../../services/AxiosInstance";
import swal from "sweetalert";
import { format } from "date-fns";

import {
  Table,
  Pagination,
  Badge,
  Dropdown,
  Spinner,
  Button,
} from "react-bootstrap";

import { CSVLink } from "react-csv";

import moment from "moment";

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const AgentDetails = ({ match, history }) => {
  const dispatch = useDispatch();
  const [agent, setAgent] = useState(null);
  const [xlsData, setXlsData] = useState([]);

  

  const [activeToggle, setActiveToggle] = useState("members");
  const [sendMessage, setSendMessage] = useState(false);

  const [replay, setReplay] = useState(false);

  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };

  const [allotCounterLoading, setAllotCounterLoading] = useState(false);
  const [total, setTotal] = useState("1");
  const [valid, setValid] = useState("365");
  const [expireDate, setExpireDate] = useState(new Date());
  const [errors, setErrors] = useState({});

  useEffect(() => {


    var currentDate = Date.now();
    var ed = moment(currentDate);
    ed.add(365, "days").format("YYYY-MM-DD hh:mm");
    setExpireDate(ed);


  }, [dispatch]);

  useEffect(() => {
    loadAgentDetails(match.params.id);
  }, [match]);

  const loadAgentDetails = async (userId) => {
    const { data } = await axiosInstance.get("admin/reseller/" + userId);

    if (data.status) {
      setAgent(data.reseller);
      document.getElementById("nav_title").innerHTML = data.reseller.name
        ? data.reseller.name
        : data.reseller.email;
    }
  };

  const rewardCounter = async (e) => {
    e.preventDefault();
    setAllotCounterLoading(true);

    const { data } = await axiosInstance.post(
      "admin/assign-instance-to-user",
      {
        instance: total,
        user_id: match.params.id,
        expire_date: expireDate,
      }
    );

    if (data.status) {
      swal("Assign Instance", data.message, "success");
      setTotal("1");
      loadAgentDetails(match.params.id);
    } else {
      swal("Assign Instance", data.message, "error");
    }

    setAllotCounterLoading(false);
  };

  function inviteStatus(status) {
    if (status == "sent") {
      return "text-warning";
    } else if (status == "accepted") {
      return "text-success";
    } else {
      return "text-danger";
    }
  }

  const memberAddress = (member) => {
    return "";
  };

  const initXlsData = async () => {
    var dummy = [];
    agent.members.map((data, i) => {
      var address = "";
      if (data.address) {
        if (data.address.addressLine1) {
          address =
            (data.address.addressLine1 ?? "") +
            ", " +
            (data.address.addressLine2 ?? "") +
            ", " +
            (data.address.city ?? "") +
            ", " +
            (data.address.state ?? "") +
            ", " +
            (data.address.country ?? "") +
            " - " +
            (data.address.zip ?? "");
        } else {
          address = "Not Available";
        }
      }

      dummy.push({
        sr_no: i + 1,
        id: data.id,
        name: data.name,
        email: data.email,
        department: data.department,
        designation: data.designation,
        card_id: data.cardId,
        address: address,
        joined: format(new Date(data.createdAt), "dd/MM/yyyy H:mma"),
      });
    });

    setXlsData(dummy);
  };

  const changeUserStatus = async (userId, status) => {
    const { data } = await axiosInstance.post("admin/user-status", {
      user_id: userId,
      status: status,
    });

    if (data.status) {
      swal("User", data.message, "success");

      var dummy = [...agent.members];

      const index = dummy.findIndex((x) => x.id === userId);

      dummy[index].status = status;
      console.log("user index - " + index);

      //setMembers(dummy);
      setAgent({ ...agent, members: dummy });
    } else {
      swal("User", data.message, "error");
    }
  };

  const changeAgentStatus = async (userId, status) => {
    const { data } = await axiosInstance.post("admin/user-status", {
      user_id: userId,
      status: status,
    });

    if (data.status) {
      swal("Agent", data.message, "success");

      var dummy = [...agent];

      const index = dummy.findIndex((x) => x.id === userId);

      dummy[index].status = status;
      console.log("user index - " + index);

      setAgent(dummy);
    } else {
      swal("Agent", data.message, "error");
    }
  };


  const onLogout = async (id) => {
   const { data } = await axiosInstance.post("admin/logout-instance", {
     instance_id: id,
   });

   if (data.status) {
     //setCampaigns(data.campaigns);
     console.log("logout success");
     swal("Logout", data.message, "success");
     loadAgentDetails(match.params.id);
   } else {
     console.log("logout failed");
     swal("Logout", data.message, "error");
   }
 };

 const onDeleteInstance = async (id) => {
   const { data } = await axiosInstance.post("admin/delete-instance", {
     instance_id: id,
   });

   if (data.status) {
     //setCampaigns(data.campaigns);
     swal("Delete", data.message, "success");
     console.log("deleted success");
     loadAgentDetails(match.params.id);
   } else {
     console.log("deleted failed");
     swal("Delete", data.message, "error");
   }
 };

 
 function dayLeft(instance){

  if(instance.expire){
    var startDate = Date.now();
    var endDate = moment(instance.expire).startOf('day');                   
    var result = endDate.diff(startDate, 'days');
    return result+" Days"
  }else{
    return "";
  }

 }

  return (
    <Fragment>
      {agent == null ? (
        <div className="text-center mt-4">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div className="profile card card-body px-3 pt-3 pb-0">
                    <div className="profile-head">
                      <div className="photo-content"></div>
                      <div className="profile-info">
                        <div className="profile-photo"></div>
                        <div className="profile-details">
                          <div className="profile-name px-3 pt-2">
                            <h4 className="text-primary mb-0">{agent.name}</h4>
                            <p>{agent.email}</p>
                          </div>

                          <div className="px-3 pt-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="profile-statistics mb-5">
                        <div className="text-center">
                          <div className="row">
                            <div className="col">
                              <h3 className="m-b-0">
                                {agent.instances.length}
                              </h3>
                              <span>Instances</span>
                            </div>

                            <div className="col">
                              <h3 className="m-b-0">{agent.members.length}</h3>
                              <span>Members</span>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col">
                              <form onSubmit={rewardCounter}>


                                <div className="form-group">
                                  <label className="mb-2 d-flex flex-wrap align-items-center">
                                    <strong className="mr-auto">
                                      Assign Instance
                                    </strong>{" "}
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={total}
                                    placeholder="Enter Instance"
                                    required
                                    onChange={(e) => setTotal(e.target.value)}
                                  />

{errors.total && (
                          <div className="text-danger fs-12">
                            {errors.total}
                          </div>
                        )}

                                </div>



                                <div className="form-group mt-2">
                                <label className="mb-2 d-flex flex-wrap align-items-center">
                                    <strong className="mr-auto">
                                      Valid
                                    </strong>{" "}
                                  </label>
                      <div className="contact-name">
                        <input
                          type="number"
                          value={valid}
                          className="form-control"
                          autocomplete="off"
                          onChange={(e) => {
                            setValid(e.target.value);

                            var currentDate = Date.now();
                            var ed = moment(currentDate);
                            ed.add(e.target.value, "days").format(
                              "YYYY-MM-DD hh:mm"
                            );

                            setExpireDate(ed);
                          }}
                          placeholder="Validity In Days"
                        />
                        {errors.valid && (
                          <div className="text-danger fs-12">
                            {errors.valid}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>

                    <div className="form-group mt-2">
                    <label className="mb-2 d-flex flex-wrap align-items-center">
                                    <strong className="mr-auto">
                                      Expire At
                                    </strong>{" "}
                                  </label>
                      <div className="contact-name">
                        <MuiPickersUtilsProvider
                          className="form-control"
                          utils={DateFnsUtils}
                        >
                          <DateTimePicker
                            inputVariant="outlined"
                            label="Expire Date"
                            value={expireDate}
                            format="dd/MM/yyyy hh:mm"
                            placeholder="dd/MM/yyyy hh:mm"
                            onChange={(date) => {
                              setExpireDate(date);
                              var startDate = moment(expireDate).startOf("day");
                              var endDate = moment(date).startOf("day");

                              var result = endDate.diff(startDate, "days");
                              setValid(result);
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        {errors.expireDate && (
                          <div className="text-danger fs-12">
                            {errors.expireDate}
                          </div>
                        )}

                        <span className="validation-text"></span>
                      </div>
                    </div>


                                <div className="text-center mt-2">
                                  {allotCounterLoading ? (
                                    <Spinner
                                      animation="border"
                                      variant="primary"
                                    />
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-block"
                                    >
                                      Assign
                                    </button>
                                  )}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8">
                  <div className="card">
                    <div className="card-body">
                      <div className="profile-tab">
                        <div className="custom-tab-1">
                          <ul className="nav nav-tabs">
                            <li
                              className="nav-item"
                              onClick={() => setActiveToggle("members")}
                            >
                              <Link
                                to="#members"
                                data-toggle="tab"
                                className={`nav-link ${
                                  activeToggle === "members"
                                    ? "active show"
                                    : ""
                                }`}
                              >
                                Members
                              </Link>
                            </li>

                            <li
                              className="nav-item"
                              onClick={() => setActiveToggle("instances")}
                            >
                              <Link
                                to="#instances"
                                data-toggle="tab"
                                className={`nav-link ${
                                  activeToggle === "instances"
                                    ? "active show"
                                    : ""
                                }`}
                              >
                                Instances
                              </Link>
                            </li>

                            <li
                              className="nav-item"
                              onClick={() => setActiveToggle("about")}
                            >
                              <Link
                                to="#about"
                                data-toggle="tab"
                                className={`nav-link ${
                                  activeToggle === "about" ? "active show" : ""
                                }`}
                              >
                                About
                              </Link>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div
                              id="members"
                              className={`tab-pane fade ${
                                activeToggle === "members" ? "active show" : ""
                              }`}
                            >
                              <div className="my-post-content pt-3">
                                <div className="mb-3 text-right">
                                  <CSVLink
                                    data={xlsData}
                                    filename={agent.name + "'s Members.csv"}
                                    className="btn btn-primary"
                                    target="_blank"
                                    asyncOnClick={true}
                                    onClick={async (event, done) => {
                                      console.log("You click the link");
                                      await initXlsData();
                                      done(); // 👍🏻 You are stopping the handling of component
                                    }}
                                  >
                                    Export
                                  </CSVLink>
                                </div>

                                <Table responsive>
                                  <thead>
                                    <tr>
                                      <th>
                                        <strong>SR NO.</strong>
                                      </th>
                                      <th>
                                        <strong>NAME</strong>
                                      </th>
                                      <th>
                                        <strong>Email</strong>
                                      </th>
                                      <th>
                                        <strong>Phone</strong>
                                      </th>

                                      <th>
                                        <strong>Joined</strong>
                                      </th>

                                      <th>
                                        <strong>Status</strong>
                                      </th>

                                      <th>
                                        <strong>Action</strong>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {agent.members.map((member, i) => (
                                      <tr key={i}>
                                        <td>
                                          <strong>{i + 1}</strong>
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            {""}
                                            <span className="w-space-no">
                                              {member.name}
                                            </span>
                                          </div>
                                        </td>
                                        <td>{member.phone} </td>
                                        <td>{member.email} </td>
                                        {/* <td>{member.total} </td> */}

                                        <td>
                                          {format(
                                            new Date(member.createdAt),
                                            "dd/MM/yyyy H:mma"
                                          )}
                                        </td>

                                        <td>
                                          {member.status === "active" ? (
                                            <Badge variant="success light">
                                              Activated
                                              <span className="ml-1 fa fa-check"></span>
                                            </Badge>
                                          ) : member.status === "deactive" ? (
                                            <Badge variant="danger light">
                                              Deactivated
                                              <span className="ml-1 fa fa-ban"></span>
                                            </Badge>
                                          ) : (
                                            <></>
                                          )}
                                        </td>

                                        <Dropdown>
                                          <Dropdown.Toggle
                                            variant=""
                                            className="table-dropdown icon-false"
                                          >
                                            <svg
                                              width="24px"
                                              height="24px"
                                              viewBox="0 0 24 24"
                                              version="1.1"
                                            >
                                              <g
                                                stroke="none"
                                                strokeWidth="1"
                                                fill="none"
                                                fillRule="evenodd"
                                              >
                                                <rect
                                                  x="0"
                                                  y="0"
                                                  width="24"
                                                  height="24"
                                                ></rect>
                                                <circle
                                                  fill="#000000"
                                                  cx="5"
                                                  cy="12"
                                                  r="2"
                                                ></circle>
                                                <circle
                                                  fill="#000000"
                                                  cx="12"
                                                  cy="12"
                                                  r="2"
                                                ></circle>
                                                <circle
                                                  fill="#000000"
                                                  cx="19"
                                                  cy="12"
                                                  r="2"
                                                ></circle>
                                              </g>
                                            </svg>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              as="button"
                                              onClick={(e) =>
                                                changeUserStatus(
                                                  member.id,
                                                  member.status === "active"
                                                    ? "deactive"
                                                    : "active"
                                                )
                                              }
                                            >
                                              {member.status === "active"
                                                ? "Deactive Now"
                                                : "Active Now"}
                                            </Dropdown.Item>

                                            {/*  <div className="dropdown-divider"></div>
                                     <Dropdown.Item
                                        href="#"
                                        className="text-danger"
                                     >
                                        Delete
                                     </Dropdown.Item> */}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            </div>

                            <div
                              id="instances"
                              className={`tab-pane fade ${
                                activeToggle === "instances"
                                  ? "active show"
                                  : ""
                              }`}
                            >
                              <div className="my-post-content pt-3">
                                <div className="mb-3 text-right"></div>

                                <Table responsive>
                                  <thead>
                                    <tr>
                                       <th>
                                          <strong>SR NO.</strong>
                                       </th>
                                       <th>Instance Key </th>
                                       <th>Name</th>
                                       <th>User</th>
                                       <th>Instance Status</th>
                                       <th>Created At</th>
                                       <th>Expire At</th>
                                       <th>Day Left</th>
                                       <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {agent.instances.map((instance, i) => (
                          <tr key={instance.code}>

                             <td>{i+1}</td>
                            <td>
                              <strong className="text-success">
                                {instance.code}
                              </strong>
                            </td>

                            <td>{instance.name ?? ""}</td>

                            <td>
                              {instance.userId ? instance.userId.name : ""}
                            </td>

                            <td>
                              <div className="">
                                {instance.status ?? ""}
                                <br />
                                <small>{instance.message ?? ""}</small>
                              </div>
                            </td>

                            <td>
                              {instance.expire?format(
                                new Date(instance.expire),
                                "dd/MM/yyyy H:mma"
                              ):""}
                            </td>

                            <td>
                              {format(
                                new Date(instance.createdAt),
                                "dd/MM/yyyy H:mma"
                              )}
                            </td>

                            <td>{dayLeft(instance)}</td>

                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="table-dropdown icon-false"
                                >
                                  <svg
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) => onLogout(instance._id)}
                                  >
                                    Logout
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) =>
                                      onDeleteInstance(instance._id)
                                    }
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                                  </tbody>
                                </Table>
                              </div>
                            </div>

                            <div
                              id="about"
                              className={`tab-pane fade ${
                                activeToggle === "about" ? "active show" : ""
                              }`}
                            >
                              <div className="row mt-4">
                                <div className="col mb-4"></div>
                              </div>
                            </div>
                            <div
                              id="profile-settings"
                              className={`tab-pane fade ${
                                activeToggle === "setting" ? "active show" : ""
                              }`}
                            >
                              <div className="pt-3"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AgentDetails;
